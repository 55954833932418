<template>
	<div class="elect-container">
		<jy-query ref="searchForm" :model="searchForm">
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="searchForm.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item label="所属机构:" prop="orgName">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="searchForm.orgName" readonly></el-input>
				</div>
			</jy-query-item>
			<jy-query-item prop="vin" label="VIN码:">
				<el-input placeholder="请输入" v-model="searchForm.vin"></el-input>
			</jy-query-item>
			<jy-query-item prop="time" label="报警时间:">
				<el-date-picker
					v-model="searchForm.time"
					type="daterange"
					placeholder="请选择"
					:clearable="false"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd"
					:picker-options="pickOptions"
				>
				</el-date-picker>
			</jy-query-item>
			<jy-query-item prop="alarmName" label="报警名称:">
				<el-input placeholder="请输入" v-model="searchForm.alarmName"></el-input>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="searchHandle" v-if="btnexist('scheduleSecureElectList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('searchForm')" v-if="btnexist('scheduleSecureElectReset')"
					>重置</el-button
				>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="exportHandle" v-if="btnexist('scheduleSecureElectExport')" :disabled="!tableList.length"
					>导出</el-button
				>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="tableList">
			<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="100"></jy-table-column>
			<jy-table-column prop="vin" label="车架号" min-width="160"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="200"></jy-table-column>
			<jy-table-column prop="routeName" label="所属线路" min-width="160"></jy-table-column>
			<jy-table-column prop="alarmName" label="报警名称" min-width="200"></jy-table-column>
			<jy-table-column prop="alarmBeginTime" label="报警开始时间" min-width="200"></jy-table-column>
			<jy-table-column prop="alarmEndTime" label="报警结束时间" min-width="200"></jy-table-column>
			<jy-operate :list="operateList" width="100"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="page.pageNum"
			:page-size="page.pageSize"
			:total="total"
		></jy-pagination>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
		<detail-dialog v-if="showDetailDialog" v-model="showDetailDialog" :rowData="rowData"></detail-dialog>
	</div>
</template>

<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import detailDialog from "./detail.vue";
import { btnMixins } from "@/common/js/button.mixin";
import dayjs from "dayjs";

export default {
	data() {
		return {
			searchForm: {
				vehicleNo: "",
				orgName: "",
				orgId: "",
				vin: "",
				time: [],
				alarmName: "",
				alarmType: 2
			},
			tableList: [],
			tableLoading: false,
			page: {
				pageSize: 10,
				pageNum: 1
			},
			total: 0,
			btnMenuId: "scheduleSecureElect",
			operateList: [
				{
					name: "查看详情",
					fun: this.viewDetail,
					isShow: () => {
						return this.btnexist("scheduleSecureElectDetail");
					}
				}
			],
			rowData: {},
			showDetailDialog: false,
			pickOptions: {
				onPick({ maxDate, minDate }) {
					const max = dayjs(maxDate);
					const min = dayjs(minDate);
					if (max.subtract(1, "month") > min) {
						this.$message({
							message: "只支持查询一个月的数据",
							type: "warning"
						});
					}
				}
			}
		};
	},
	components: {
		institutionsTree,
		detailDialog
	},
	mixins: [btnMixins],
	created() {
		this.setDefaultSearchTime();
		this.getTableList();
	},
	methods: {
		// 默认查询时间
		setDefaultSearchTime() {
			const cDay = dayjs().format("YYYY-MM-DD");
			this.searchForm.time = [cDay, cDay];
		},
		getParams() {
			const [alarmBeginTime = "", alarmEndTime = ""] = this.searchForm.time || [];
			const params = {
				...this.searchForm,
				...this.page,
				alarmBeginTime: `${alarmBeginTime} 00:00:00`,
				alarmEndTime: `${alarmEndTime} 23:59:59`
			};
			delete params.time;
			return params;
		},
		async getTableList() {
			const [alarmBeginTime = "", alarmEndTime = ""] = this.searchForm.time || [];
			if (dayjs(alarmEndTime).subtract(1, "month") > dayjs(alarmBeginTime)) {
				this.$message({
					message: "只支持查询一个月的数据",
					type: "warning"
				});
				return;
			}
			const params = this.getParams();
			this.tableLoading = true;
			const url = `/crrc-palm-bus/safetyManagement/electronicFenceAlarm/list`;
			this.$http
				.post(url, params, { baseType: "base" })
				.then(res => {
					this.tableList = res?.records || [];
					this.total = res?.total || 0;
				})
				.finally(() => {
					this.tableLoading = false;
				});
		},
		searchHandle() {
			this.page.pageNum = 1;
			this.getTableList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.searchForm.orgId = "";
			this.page.pageNum = 1;
			this.getTableList();
		},
		exportHandle() {
			const params = this.getParams();
			const url = `/crrc-palm-bus/safetyManagement/export`;
			this.$http.post(url, params, { baseType: "base", isExcel: true }).then(res => {
				console.log(res, "---res");
				if (res.status == 200) {
					this.$util.funDownload(res.data, "电子围栏报警信息");
				} else {
					this.$message({
						message: "导出失败",
						type: "warning"
					});
				}
			});
		},
		// 所属机构
		addInstitutions(data) {
			console.log(data);
			this.searchForm.orgId = data.orgId;
			this.searchForm.orgName = data.orgNa;
		},
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		viewDetail(row) {
			this.rowData = row;
			this.showDetailDialog = true;
		},
		// 分页
		handleSizeChange(val) {
			this.page.pageNum = 1;
			this.page.pageSize = val;
			this.getTableList();
		},
		handleCurrentChange(val) {
			this.page.pageNum = val;
			this.getTableList();
		}
	}
};
</script>
