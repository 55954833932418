<template>
	<el-dialog
		:title="`${rowData.alarmName}：${rowData.vehicleNo} 报警时间：${rowData.alarmBeginTime}`"
		:visible.sync="detailDialog"
		width="60%"
	>
		<div>
			<div class="track-map" ref="trackContainer"></div>
		</div>
		<div slot="footer">
			<el-button @click="detailDialog = false">关闭</el-button>
			<el-button type="primary" @click="playHandle">{{ isRunning ? "暂停" : "播放" }}</el-button>
		</div>
	</el-dialog>
</template>

<script>
import gcoord from "gcoord";
import LuShu from "@/common/js/BMapLib/LuShu.js";
import startImage from "@/assets/startIcon.png";
import endImage from "@/assets/endIcon.png";
import dayjs from "dayjs";

let trackMap = null,
	lushu = null;
export default {
	data() {
		return {
			routeId: "",
			clientId: "",
			isRunning: false
		};
	},
	props: {
		value: Boolean,
		rowData: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		detailDialog: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			}
		}
	},
	created() {
		this.init();
	},
	mounted() {
		this.$nextTick(() => {
			this.initMap();
		});
	},
	destroyed() {
		if (this.isRunning) {
			this.stop();
		}
		trackMap.clearOverlays();
		trackMap = null;
		lushu = null;
	},
	methods: {
		init() {
			console.log(this.rowData);
			this.routeId = this.rowData.routeId;
			this.clientId = this.rowData.deviceId;
			this.getTrack();
		},
		initMap() {
			const dom = this.$refs.trackContainer;
			trackMap = new BMap.Map(dom);
			trackMap.enableScrollWheelZoom();
			trackMap.centerAndZoom(new BMap.Point(89.187441, 42.957145), 13);
		},
		playHandle() {
			this.isRunning ? this.pause() : this.start();
		},
		start() {
			lushu.start();
			this.isRunning = true;
		},
		pause() {
			lushu.pause();
			this.isRunning = false;
		},
		stop() {
			lushu.stop();
			this.isRunning = false;
		},
		getTrack() {
			let url = "/waybillActual/getGpsDataByDevice";
			// 报警时间前后三分钟
			const beginTime = dayjs(this.rowData.alarmBeginTime).subtract(3, "minute").format("YYYY-MM-DD HH:mm:ss");
			const endTime = dayjs(this.rowData.alarmBeginTime).add(3, "minute").format("YYYY-MM-DD HH:mm:ss");
			this.$http
				.post(url, {
					deviceNo: this.clientId,
					routeId: this.routeId,
					beginTime,
					endTime
				})
				.then(res => {
					const arrPois = res.detail.map(v => {
						const [lng, lat] = gcoord.transform([v.lng, v.lat], gcoord.WGS84, gcoord.BD09);
						return new BMap.Point(lng, lat);
					});
					this.getPolyline(arrPois);
					// 显示围栏，并返回围栏的坐标点
					const p = this.getWeiLan();
					let points = [...arrPois];
					if (p) {
						points = [...points, ...p];
					}
					trackMap.setViewport(points);
					trackMap.setZoom(trackMap.getZoom() - 1);

					//   添加标注
					if (arrPois.length) {
						let startPoint = new window.BMap.Point(arrPois[0].lng, arrPois[0].lat);
						let endPoint = new window.BMap.Point(arrPois[arrPois.length - 1].lng, arrPois[arrPois.length - 1].lat);
						var startIcon = new BMap.Icon(startImage, new BMap.Size(50, 50));
						var endIcon = new BMap.Icon(endImage, new BMap.Size(50, 50));
						let marker = new BMap.Marker(startPoint, {
							icon: startIcon
						});
						let endmarker = new BMap.Marker(endPoint, {
							icon: endIcon
						});
						trackMap.addOverlay(marker);
						trackMap.addOverlay(endmarker);
					}
					this.getLushu(arrPois);
				});
		},
		// 创建轨迹
		getPolyline(trackList) {
			let tempList = [trackList[0]];
			console.log("tempList", tempList);
			for (let index = 1; index < trackList.length; index++) {
				const f = trackList[index - 1];
				const l = trackList[index];
				if (BMapLib.GeoUtils.getDistance(f, l) <= 300) {
					tempList.push(l);
				} else {
					// 绘制
					const polyline = new BMap.Polyline(tempList, {
						strokeColor: "blue", //设置颜色
						strokeWeight: 5, //宽度
						strokeOpacity: 0.5 //透明度
					});
					trackMap.addOverlay(polyline);
					tempList = [l];
				}
			}
			if (tempList.length > 1) {
				const polyline = new BMap.Polyline(tempList, {
					strokeColor: "blue", //设置颜色
					strokeWeight: 5, //宽度
					strokeOpacity: 0.5 //透明度
				});
				trackMap.addOverlay(polyline);
			}
		},
		// 创建路书
		getLushu(arrPois) {
			lushu = new LuShu(trackMap, arrPois, {
				defaultContent: "", //"从天安门到百度大厦"
				autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
				icon: new BMap.Icon("https://developer.baidu.com/map/jsdemo/img/car.png", new BMap.Size(52, 26), {
					anchor: new BMap.Size(27, 13)
				}),
				speed: 30, //运行速度
				enableRotation: true, //是否设置marker随着道路的走向进行旋转
				landmarkPois: [
					{
						lng: 116.314782,
						lat: 39.913508,
						// html: "加油站",
						pauseTime: 2
					}
				]
			});
		},
		getWeiLan() {
			if (this.rowData.electronicFenceVo) {
				const { fenceData } = this.rowData.electronicFenceVo;
				// 默认有围栏数据
				if (fenceData) {
					// 添加默认围栏数据
					const fenceDataObj = JSON.parse(fenceData);
					if (this.rowData.electronicFenceVo.fenceType === 1) {
						// 圆形围栏
						const { _symbol, _radius, _coordinates } = fenceDataObj;
						const point = new BMap.Point(_coordinates.x, _coordinates.y);
						const circle = new BMap.Circle(point, Math.round(_radius), {
							strokeColor: _symbol.lineColor,
							strokeWeight: _symbol.lineWidth,
							strokeOpacity: _symbol.lineOpacity
						});
						trackMap.addOverlay(circle);
						return [point];
					} else {
						// 其他形状
						const coordinates = fenceDataObj._coordinates?.map(item => {
							return new BMap.Point(item.x, item.y);
						});
						console.log("coordinates", coordinates);
						var polygon = new BMap.Polygon(coordinates, {
							strokeColor: "red", //设置颜色
							strokeWeight: 5, //宽度
							strokeOpacity: 0.5 //透明度
						});
						trackMap.addOverlay(polygon);
						return coordinates;
					}
				}
			}
		}
	}
};
</script>

<style scoped>
.track-map {
	height: 330px;
}
</style>
